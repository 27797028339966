<template>
    <div class="bc-wrapper bc-horizontal">
        <h1>Application Form</h1>
        <!-- <p>We need your e-mail and phone number for your account manager to contact you.</p> -->
        <div style="margin: 50px 0;">
            <div style="margin: 50px 0;">
                <div>
                    <div class="stripe">
                        <div class="line" />
                        <h2 class="center-text">
                            Email verification
                        </h2>
                    </div>
                    <span>
                        <InformationOutline class="hint" />
                        We need your e-mail and phone number for your account manager to contact you.
                    </span>
                </div>
                <div class="form-affiliate">
                    <Email v-if="!affiliateEmailVerified" />
                    <div
                        v-else
                        class="email-success-verification"
                    >
                        <div class="email-success-verification__num">
                            <span>Email: </span>
                            <strong>  {{ getterEmail }}</strong>
                        </div>
                        <div class="email-success-verification__icon">
                            <CheckIcon />
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin: 50px 0;">
                <div>
                    <div class="stripe">
                        <div class="line" />
                        <h2 class="center-text">
                            Phone verification
                        </h2>
                    </div>
                    <span>
                        <InformationOutline class="hint" />
                        We need your phone number for your account manager to contact you.
                    </span>
                </div>
                <div class="form-affiliate">
                    <!--                    <p>-->
                    <!--                        We will contact you by e-mail so we need you to verify your address.-->
                    <!--                    </p>-->
                    <PhoneNumber
                        v-if="!affiliatePhoneVerified"
                        @success="success"
                    />
                    <div
                        v-else
                        class="phone-success-verification"
                    >
                        <div class="phone-success-verification__num">
                            <span>Phone: </span>
                            <strong>  +({{ getterPhoneNumber.phone_code }}) {{ getterPhoneNumber.phone }}</strong>
                        </div>
                        <div class="phone-success-verification__icon">
                            <CheckIcon />
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin: 50px 0;">
                <div>
                    <div class="stripe">
                        <div class="line" />
                        <h2 class="center-text">
                            Payment settings
                        </h2>
                    </div>
                    <span>
                        <InformationOutline class="hint" />
                        You can select that payment method that will be used to cash out your <b>affiliate earnings</b>.
                    </span>
                </div>
                <div class="form-affiliate">
                    <div class="form-affiliate__input">
                        <!--                        <form-select-->
                        <!--                            v-model="payout_provider"-->
                        <!--                            :items="paymentArray"-->
                        <!--                            :value-select="payout_provider"-->
                        <!--                            name="Payment"-->
                        <!--                        />-->
                        <div
                            v-for="(payment, index) in paymentArray"
                            :key="index"
                        >
                            <button
                                class="btn-payment"
                                :class="payout_provider === payment.value ? 'btn-payment-active' : 'btn-payment-inactive'"
                                @click="payout_provider = payment.value"
                            >
                                {{ payment.text }}
                            </button>
                        </div>
                    </div>
                    <div class="form-affiliate-field">
                        <affiliate-payment-form
                            v-if="payout_provider === 'wire'"
                            :ref="payout_provider"
                            :fields="fields[payout_provider]"
                            @update="onUpdate($event, payout_provider)"
                        />
                        <affiliate-payment-u-s-d-t
                            v-if="payout_provider === 'usdt'"
                            :ref="payout_provider"
                            :fields="fields[payout_provider]"
                            @update="onUpdate($event, payout_provider)"
                        />
                        <affiliate-payment-stripe
                            v-if="payout_provider === 'stripe'"
                            :ref="payout_provider"
                            :fields="fields[payout_provider]"
                            @update="onUpdate($event, payout_provider)"
                        />
                        <affiliate-payment-paypal
                            v-if="payout_provider === 'paypal'"
                            :ref="payout_provider"
                            :fields="fields[payout_provider]"
                            @update="onUpdate($event, payout_provider)"
                        />
                    </div>
                </div>
            </div>

            <div class="stripe">
                <div class="line" />
                <h2 class="center-text">
                    terms & conditions
                </h2>
            </div>

            <Agrement
                @terms-agree="Agreement"
            />
            <div class="affiliate_btn">
                <custom-button
                    class="btn-base btn-main button"
                    style="width: 100%"
                    :disabled="!(affiliateEmailVerified && affiliatePhoneVerified && agrement)"
                    :loading="loading"
                    @on-btn-click="getMoneyMakerStatus()"
                >
                    Submit
                </custom-button>
            </div>
        </div>
    </div>
</template>

<script>

import { eventBus } from '@/helpers/event-bus';
import { mapGetters, createNamespacedHelpers } from 'vuex'
import Email from '@/components/common/form-elements/email-verify/Email';
import PhoneNumber from '@/components/common/form-elements/phone/PhoneNumber';
import InformationOutline from 'mdi-vue/InformationOutline';

// validation
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import formValidationMixin from '@/mixins/form-validation-mixin'
import { validationRules } from '@/validation/affiliate/rules'
import { formMessages } from '@/validation/affiliate/messages'

import {
    ACTION_CLIENT_UPDATE_PHONE,
    ACTION_CLIENT_UPDATE_SETTINGS,
    ACTION_UPDATE_CLIENT_DATA
} from '@/store/modules/client/action-types';

import { mapPhoneFields } from '@/store/modules/phone'

import CheckIcon from 'mdi-vue/Check';

import Agrement from '@/components/AgrementAffiliate.vue';

import { affiliateSignup, affiliateListAvailable } from '../../../services/referral/index'
import AffiliatePaymentForm from '../setting/payment/AffiliatePaymentForm.vue';
import AffiliatePaymentUSDT from '../setting/payment/AffiliatePaymentUSDT.vue';
import AffiliatePaymentStripe from '../setting/payment/AffiliatePaymentStripe.vue';
import AffiliatePaymentPaypal from '../setting/payment/AffiliatePaymentPaypal.vue';

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client');

export default {
    name: 'AffiliateSignUp',
    components: {
        AffiliatePaymentPaypal,
        AffiliatePaymentStripe,
        AffiliatePaymentUSDT,
        AffiliatePaymentForm,
        CheckIcon,
        Email,
        PhoneNumber,
        InformationOutline,
        // WireForm,
        Agrement
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    data() {
        return {
            loading: false,
            payout_provider: 'paypal',
            payout_account: '',
            paymentArray: [],
            fields: [],
            wire: [],
            AffiliatePayload: null,
            agrement: false,
            formData: {}
        }
    },
    validations: {
        ...validationRules
    },
    computed: {
        ...mapGetters('client', [
            'getterCallParams',
            'isPhoneOnRecord',
            'affiliateEmailVerified',
            'affiliatePhoneVerified',
            'getterPhoneNumber',
            'getterEmail'
        ]),
        ...mapPhoneFields([
            'form_data.country',
            'form_data.phone_number'
        ])
    },
    async created() {
        await this.listAvailable()
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        affiliateSignup,
        affiliateListAvailable,
        ...mapClientActions({
            updatePhone: ACTION_CLIENT_UPDATE_PHONE,
            updateSettings: ACTION_CLIENT_UPDATE_SETTINGS,
            updateData: ACTION_UPDATE_CLIENT_DATA
        }),
        async listAvailable() {
            try {
                this.loading = true
                const { fields, types } = await this.affiliateListAvailable()
                this.paymentArray = types.map(type => ({
                    value: type.key,
                    text: type.title
                }))
                this.fields = fields
                this.wire = fields.wire
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error');
            } finally {
                this.loading = false
            }
        },
        async onSave() {
            try {
                this.loading = true
                await this.updatePhone({
                    phone_code: this.country.value,
                    phone: this.phone_number
                })
                // eventBus.$emit('showSnackBar', 'Your phone has been saved', 'success');
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error');
            } finally {
                this.loading = false
            }
        },
        async success() {
            await this.onSave()
            await this.updateData()
        },
        onUpdate(payload, type) {
            this.$set(this.formData, type, {
                ...this.formData?.[type],
                ...payload
            })
        },
        preValidate() {
            this.$refs[this.payout_provider].$v.$touch()
            return !(this.$refs[this.payout_provider].$v.$dirty && !this.$refs[this.payout_provider].$v.$anyError)
        },
        async getMoneyMakerStatus() {
            if (this.preValidate()) {
                eventBus.$emit('showSnackBar', 'Please complete the form', 'error');
                return
            }
            this.loading = true
            await this.affiliateSignup({
                affiliate_paymethod_type: this.payout_provider,
                affiliate_paymethod_data: this.formData[this.payout_provider]
            })
            this.loading = false
            this.payout_account = ''
            this.$router.push({ name: 'affiliate-success' })
        },
        Agreement(isAgreement) {
            this.agrement = isAgreement
        }
    }
}
</script>

<style lang="scss">

.my-3 {
    margin-top: 20px;
}
.btn-disabled {
    pointer-events: none;
}

.form-block__body {
    &--onboarding {
        border-radius: 0 12px 12px 0;
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
    .phone_wrapper {
        .phone__row {
            flex-wrap: nowrap;
            .phone-verification__btn {
                width: inherit;
                margin-left: 5px;
            }
        }
    }
    .form-input__fieldset input {
        font-size: 17px !important;
    }
}

.register-terms-and-privacy{
    &--mobile{
        display: none;
        @media (max-width: 650px) {
            margin-top: 15px;
            display: flex;
        }
    }
    &--desctop{
        @media (max-width: 650px) {
            display: none;
        }
    }
}
.onboarding {
    &-title {
        font-size: 32px;
        line-height: 45px;
        font-weight: 700;
        color: #111720;
        margin-bottom: 10px;
    }
    &-container {
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    &-btns {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
    &-checkobox {
        &-group {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
    }
    &-steps {
        display: flex;
        max-width: 350px;
        width: 100%;
        margin: 0 auto 20px;
        color: $main-color;
        >div{
            position: relative;
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            &:first-child {
                flex-grow: 0;
                .onboarding-steps__step-progress {
                    display: none;
                }
            }
        }
        &__step {
            z-index: 1;
            padding: 10px;
            width: 50px;
            height: 50px;
            border: 4px solid;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ddd;
            font-size: 28px;
            font-weight: 700;
            border-radius: 50px;
            background: #fff;
            position: relative;
            &--finish {
                color: $main-color;
            }
            &-progress {
                display: block;
                position: absolute;
                height: 2px;
                background: #ddd;
                background: repeating-linear-gradient(
                    to right,
                    #ddd,
                    #ddd 10px,
                    transparent 10px,
                    transparent 20px
                );
                top: 50%;
                left: 10px;
                width: calc(100% - 60px);
                &--finish {
                    height: 2px;
                    background: repeating-linear-gradient(
                        to right,
                        $main-color,
                        $main-color 10px,
                        transparent 10px,
                        transparent 20px
                    );
                }
            }
        }
    }
}
.component-container {
    position: relative;
    min-height: 390px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @media (max-width:480px) {
        min-height: 410px;
    }
    >.component {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right:0;
        display: grid;
        align-content: start;
        >.component-title {
            margin: 16px 0;
            font-weight: 500;
            &:first-child {
                margin-top: 0;
            }
        }
    }
}
.slideInLeft-enter-active,
.slideInLeft-leave-active {
    transition: all .3s;
    opacity: 1;
    transform: translateX(0);
}
.slideInLeft-enter
{
    opacity: 0;
    transform: translateX(100%);
}
.slideInLeft-leave-to
{
    opacity: 0;
    transform: translateX(-100%);
}
.form-btn-next {
    margin-left: auto;
}

.form-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
}

.form-affiliate{
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    gap: 20px;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    @media (max-width:992px) {
        width: 100%;
    }
    &__input{
        display:flex;
        align-items: baseline;
        flex-wrap: wrap;
        gap: 15px;
        width: 100%;
        padding-bottom: 30px;
        margin-bottom: 10px;
        border-bottom: 1px solid #D3D9E2;
        @media (max-width:640px) {
            flex-direction: column;
        }
    }
}

.form-affiliate .email_wrapper  {
    width: 100%;
    div .email__row .email-varify__value{
        max-width: calc(100% - 110px);
        font-weight: normal;
        font-size: 12px;
    }
}

.form-affiliate .phone_wrapper  {
    width: 100%;
}

.hint {
    svg {
        fill: $main-color;
    }
}

.affiliate_btn{
    margin: 50px 0;
    .btn-disabled {
        background: rgba(grey, 0.1) !important;
        color: grey;
        box-shadow: 0px 20px 24px -4px rgb(16 24 40 / 8%), 0px 8px 8px -4px rgb(16 24 40 / 3%);
    }
}

.form-affiliate-field{
    width: 75%;
    @media (max-width:768px) {
        width: 100%;
    }
}

.btn-payment{
    padding: 7px 14px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    letter-spacing: 2px;
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(25,50,71,.2);
}

.btn-payment-active{
    background-color: #1bce3c;
    border: 2px solid #1bce3c;
    color: #fff;
}

.btn-payment-inactive{
    background-color: rgba(gray, 0.2);
    border: 2px solid transparent;
    color: gray;
}

.stripe {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 50px;
}
.line {
    width: 100%;
    height: 1px;
    background-color: #000;
    position: absolute;
}
.center-text {
    text-align: center;
    font-size:18px !important;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #EAEBEF;
    padding: 5px 10px;
    z-index: 2;
}

</style>
